/**
 * Provides the current traffic model ID which is the traffic model number for the viewport needed for the Traffic API -
 * Incidents Layer, and the {{#crossLink "IncidentDetails"}}Traffic API - Incident Details{{/crossLink}}
 * calls.
 *
 * It is updated every minute, and is valid for two minutes before it times out.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class incidentViewport
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses ProtocolMixin
 * @constructor
 * @param {Object} [options]
 * @example
 * ```js
 * function callbackFn(response) {
 *   console.log(response);
 * }
 * tt.services.incidentViewport({
 *   key: <Your API key>
 * }).then(callbackFn);
 * ```
 */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import validators from './validators';
import { incidentViewportRest } from '../rest/incidentViewport';
import serviceFactory from './serviceFactory';

const fields = {
    key: {
        validators: [validators.key]
    },
    protocol: {
        validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
    },
    sessionId: {
        validators: [validators.string]
    }
};

function handleServiceCall(data) {
    return incidentViewportRest(fields, data);
}

export const incidentViewport =
    serviceFactory(fields, SERVICE_TYPES.TRAFFIC_INCIDENTS, 'incidentViewport', handleServiceCall);
