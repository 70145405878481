/**
 * Get the charging connector data consisting of type and numbers of the existing charging stations,
 * based on EV station ID retrieved from a FuzzySearch request called with connectorSet parameter.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 *
 * function callbackFn(results) {
 *   console.log(results);
 * }
 *
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: 'station',
 *      connectorSet: 'Tesla'
 * }).then(function(response) {
 *      var chargingStationID = response.results[0].dataSources.chargingAvailability.id;
 *      tt.services.evChargingStationsAvailability({
 *          key: <Your API key>,
 *          chargingAvailability: chargingStationID
 *      }).then(callbackFn)
 *   });
 * ```
 *
 * @class evChargingStationsAvailability
 * @namespace Services.services
 * @uses KeyMixin
 * @uses BatchMixin
 * @uses chargingAvailabilityMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options]
 */

import { SERVICE_TYPES } from 'Core/serviceTypes';
import validators from '../validators';
import serviceFactory from '../serviceFactory';
import { chargingAvailabilityRest } from '../../rest/chargingAvailability';
import batchModel from '../../model/search/batchModel';

const fields = {
    key: {
        validators: [validators.key]
    },

    chargingAvailability: {
        validators: [validators.string],
        required: true
    }
};

function handleServiceCall(data) {
    return chargingAvailabilityRest.chargingAvailability(fields, data);

}
function handleBatchServiceCall(data) {
    return chargingAvailabilityRest.batch(fields, data).then(response => batchModel(response));
}

export const evChargingStationsAvailability =
    serviceFactory(
        fields,
        SERVICE_TYPES.EXTENDED_SEARCH,
        'chargingAvailability',
        handleServiceCall,
        handleBatchServiceCall
    );
