import validators from '../../services/validators';
import converters from '../../services/converters';
import { SEARCH } from '../../common/searchTypes';
import parameterApplications from '../../common/parameterApplications';
import postValidators from '../postValidators';

export default {
    create: () => {
        return {
            __all: {
                validators: [postValidators.notRequiredWithCategoryNorBrandSet]
            },
            key: {
                validators: [validators.string]
            },
            query: {
                validators: [
                    validators.string
                ],
                application: parameterApplications.PATH,
                defaultValue: ''
            },
            typeahead: {
                validators: [validators.bool]
            },
            limit: {
                validators: [validators.limit]
            },
            offset: {
                validators: [validators.offset],
                name: 'ofs'
            },
            language: {
                validators: [validators.languageCode],
                converters: [converters.language]
            },
            countrySet: {
                validators: [validators.countrySet]
            },
            radius: {
                validators: [validators.naturalInteger]
            },
            center: {
                converters: [converters.point], validators: [validators.point],
                cast: (center, requestOptions) => {
                    const coordinates = center.split(',');
                    requestOptions.lat = coordinates[0];
                    requestOptions.lon = coordinates[1];
                }
            },
            type: {
                validators: [validators.string],
                defaultValue: SEARCH,
                application: parameterApplications.PATH
            },
            bestResult: {
                validators: [validators.bool],
                cast: (bestResults, requestOptions) => {
                    if (!bestResults) {
                        return;
                    }
                    requestOptions.limit = 1;
                    requestOptions.ofs = 0;
                }
            },
            protocol: {
                validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
            },
            extendedPostalCodesFor: {
                validators: [validators.string]
            },
            view: {
                validators: [validators.oneOfValue(['IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'AR', 'CN'], 'view')]
            },
            brandSet: {
                validators: [validators.string]
            },
            categorySet: {
                validators: [validators.string]
            },
            connectorSet: {
                validators: [validators.connectorSet]
            },
            openingHours: {
                validators: [validators.oneOfValue(['nextSevenDays'], 'openingHours parameter')]
            }
        };
    }
};

export const boundingBox = () => ({
    validators: [validators.boundingBox],
    converters: [converters.boundingBox],
    cast: (boundingBox, requestOptions) => {
        requestOptions.topLeft = `${boundingBox.maxLat},${boundingBox.minLon}`;
        requestOptions.btmRight = `${boundingBox.minLat},${boundingBox.maxLon}`;
    }
});

