import http from '@http';
import asyncRedirectModeFactory from './asyncRedirectModeFactory';

export default function makeBatchRequest(options) {
    let endpoint;

    switch (options.batchMode) {
    case 'async':
        endpoint = options.endpoints.async;
        break;
    case 'sync':
        endpoint = options.endpoints.sync;
        break;
    default: // same as redirect
        endpoint = options.endpoints.async;
        options.queryParams.redirectMode = 'manual';
        break;
    }

    if (options.batchMode !== 'sync' && options.waitTimeSeconds) {
        options.queryParams.waitTimeSeconds = options.waitTimeSeconds;
    } else if (options.queryParams && options.queryParams.waitTimeSeconds) {
        delete options.queryParams.waitTimeSeconds;
    }

    return http.post({
        url: '{protocol}://' + endpoint,
        queryParameters: options.queryParams,
        bodyParameters: options.bodyParams,
        requestType: 'batch'
    }).then((response) => {
        if (options.queryParams.redirectMode === 'manual') {
            // if batch request is async with manual mode - fetch the results using the url provided
            return asyncRedirectModeFactory(response);
        }
        return response;
    });
}
