import axios from 'axios';
import template from './template';
import { getAnalyticsHeader } from './analytics';
import { isString, isObject } from 'lodash';

function createKeyValueString(key, value) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function mapQueryParamToKeyValueString(options, key) {
    const queryParamValue = options.queryParameters[key];
    let result = '';
    if (Array.isArray(queryParamValue)) {
        result = queryParamValue.map(function(value) {
            return createKeyValueString(key, value);
        }).join('&');
    } else {
        result = createKeyValueString(key, options.queryParameters[key]);
    }
    return result;
}

function withQueryParams(options, url) {
    if (!options.queryParameters) {
        return url;
    }

    const queryString = Object.keys(options.queryParameters)
        .map(function(key) {
            return mapQueryParamToKeyValueString(options, key);
        })
        .join('&');
    return url + '?' + queryString;
}

function stringify(obj) {
    try {
        return JSON.stringify(obj);
    } catch (ex) {
        return null;
    }
}

function setHeader(requestOptions, headerName, headerValue) {
    if (!requestOptions.headers) {
        requestOptions.headers = {};
    }

    const header = requestOptions.headers[headerName] || requestOptions.headers[headerName.toLowerCase()];
    if (!header) {
        requestOptions.headers[headerName] = headerValue;
    }
}

function addObjectBodyParams(bodyParams, requestOptions) {
    const body = stringify(bodyParams);
    if (!body) {
        throw new Error('Unsupported request body type: ' + bodyParams);
    }
    setHeader(requestOptions, 'Content-Type', 'application/json');
    return body;
}

function addBodyParams(options, requestOptions) {
    const bodyParams = options.bodyParameters;
    let body;
    if (!bodyParams) {
        return;
    }

    if (isObject(bodyParams)) {
        body = addObjectBodyParams(bodyParams, requestOptions);
    } else if (isString(bodyParams)) {
        body = bodyParams;
    }

    requestOptions.data = body;
}

const errorResponse = response => {
    // preflight response failed
    if (!response.data || !response.status) {
        return response;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const performRequest = (options, url, requestOptions) => {
    return axios(withQueryParams(options, url), requestOptions)
        .then((response) => {
            if (options._getOriginalResponse) {
                return response;
            }
            if (options.requestType === 'batch' && response.status === 202) {
                return response.headers.location;
            }
            return response.data;
        })
        .catch((err) => {
            err = err.response ? err.response : err;
            err = options._getOriginalResponse ? err : errorResponse(err);

            return Promise.reject(err);
        });
};

export const json = {
    /**
     * Executes a request and processes the response as JSON.
     * @method get
     * @param {Object} options Specifies the details of the request.
     * @param {String} options.url The URL to request.
     * @param {Object} [options.queryParameters] The data to pass as GET parameters with the request.
     * @param {Object} [options.pathParameters] The data to use as {placeholders} in url.
     * @param {Function} [transformResponse] The custom response transformer function.
     * @return {Promise} Returns a promise
     */
    get: function(options, transformResponse) {
        options.pathParameters = options.pathParameters || {};
        options.pathParameters.contentType = 'json';
        options.pathParameters.protocol = options.pathParameters.protocol || 'https';

        const url = template(options.url, options.pathParameters);
        const headers = getAnalyticsHeader();
        headers['Accept'] = 'application/json'; //eslint-disable-line dot-notation

        let requestOptions = {
            method: 'GET',
            headers: headers,
            mode: 'cors'
        };

        if (transformResponse) {
            requestOptions = { ...requestOptions, transformResponse };
        }

        return performRequest(options, url, requestOptions);
    },

    /**
     * Executes a request and processes the response as JSON.
     * @method post
     * @param {Object} options Specifies the details of the request.
     * @param {String} options.url The URL to request.
     * @param {Object} [options.queryParameters] The data to pass as Url parameters with the request.
     * @param {Object} [options.pathParameters] The data to use as {placeholders} in url.
     * @param {Object} [options.bodyParameters] The data to pass to request body.
     * @return {Promise} Returns a promise
     */
    post: function(options) {
        options.pathParameters = options.pathParameters || {};
        options.pathParameters.contentType = 'json';
        options.pathParameters.protocol = options.pathParameters.protocol || 'https';

        const url = template(options.url, options.pathParameters);
        const headers = getAnalyticsHeader();
        headers['Accept'] = 'application/json'; //eslint-disable-line dot-notation

        const requestOptions = {
            method: 'POST',
            headers: headers,
            mode: 'cors',
            redirect: 'follow'
        };

        addBodyParams(options, requestOptions);

        return performRequest(options, url, requestOptions);
    }
};
