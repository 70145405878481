import {
    batchSearchQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint,
    geocodeEndpoint
} from 'endpoints';
import { singleRequestServiceFactory } from './request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from './request/batchRequestServiceFactory';

export const geocodeRest = {
    geocode: singleRequestServiceFactory(geocodeEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchSearchQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    })
};

