import geocodeModel from './geocode';

export default (data) => {
    if (!(data.batchItems instanceof Array)) {
        return null;
    }

    data.batchItems = data.batchItems.map((batchItem) => {
        if (batchItem.response.error) {
            return { error: batchItem.response.error};
        } else {
            return geocodeModel(batchItem.response);
        }
    });
    return data;
};
