import PointFacade from '../pointFacade';
import getRouteFeature from './helpers/getRouteFeature';

const pointFacade = new PointFacade();

const newFeatureCollection = () => {
    return {
        type: 'FeatureCollection',
        features: []
    };
};

const toGeoJson = (data) => {
    const result = newFeatureCollection();
    if (!data || !data.routes) {
        return result;
    }
    for (let i = 0; i < data.routes.length; i += 1) {
        result.features.push(getRouteFeature(data.routes[i]));
    }
    return result;
};

export default (data) => {
    const routes = data.routes;

    // convert points
    if (routes && routes.length) {
        routes.forEach(route => {
            route.legs.forEach(leg => {
                leg.points = pointFacade.convert(leg.points);
            });
        });
    }

    return Object.assign(data, {
        toGeoJson: () => toGeoJson(data)
    });
};
