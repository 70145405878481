/**
 * Base class for all the Search services.
 *
 * @private
 * @class Search
 * @module Services
 * @uses ProtocolMixin
 * @uses KeyMixin
 * @uses LimitMixin
 * @uses LanguageMixin
 * @uses BatchMixin
 *
 * @constructor
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import utils from '../utils';
import { searchRest } from '../../rest/search';
import searchModel from '../../model/search/search';
import batchModel from '../../model/search/batchModel';
import searchParams from './searchParameters';
import serviceFactory from '../serviceFactory';

class Search {
    constructor(options) {
        this.options = options;
        this.defaultFields = utils.clone(searchParams.create());
        this.fields = {};
    }

    _getRestService = () => {
        return searchRest;
    }

    handleBatchServiceCall = (data) => {
        return this._getRestService().batch(this.fields, data).then(results => batchModel(results));
    }

    handleServiceCall = (options) => {
        return this._getRestService().search(this.fields, options)
            .then((response) => options.bestResult ? searchModel(response.results[0]) : searchModel(response));
    }

    construct = (serviceName) => {
        this.fields = utils.addFields(this.fields, this.defaultFields);
        const service = serviceFactory(
            this.fields,
            SERVICE_TYPES.SEARCH,
            serviceName || 'search',
            this.handleServiceCall,
            this.handleBatchServiceCall
        );
        return service(this.options);
    }
}

export default Search;
