import { singleRequestServiceFactory } from '../rest/request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from '../rest/request/batchRequestServiceFactory';
import {
    nearbySearchEndpoint,
    batchNearbySearchQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

export const nearbySearchRest = {
    search: singleRequestServiceFactory(nearbySearchEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchNearbySearchQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    })
};

