import { singleRequestServiceFactory } from '../rest/request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from '../rest/request/batchRequestServiceFactory';
import {
    chargingAvailabilityEndpoint,
    batchChargingAvailabilityQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

export const chargingAvailabilityRest = {
    chargingAvailability: singleRequestServiceFactory(chargingAvailabilityEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchChargingAvailabilityQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    })
};
