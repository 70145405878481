/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/**
 * Makes a request using the TomTom
 * [Search API - Structured Geocode](STRUCTURED_GEOCODE_URL).
 *
 * Parameters can be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 *
 * @class structuredGeocode
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses LimitMixin
 * @uses OffsetMixin
 * @uses LanguageMixin
 * @uses ProtocolMixin
 * @uses BestResultMixin
 * @uses BatchMixin
 * @uses ViewMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the structured geocoding call,
 *     or an array of such options objects to perform batch request.
 *
 * @example
 * ```javascript
 * function callbackFn(result) {
 *   console.log(result);
 * };
 * tt.services.structuredGeocode({
 *   key: <Your API key>,
 *   countryCode: 'GB'
 * });
 * ```
 */
/* eslint-enable max-len */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import { structuredGeocodeRest } from '../../rest/structuredGeocode';
import geocodeModel from '../../model/geocode/geocode';
import batchModel from '../../model/geocode/batchGeocode';
import structureGeocodeParameters from './structureGeocodeParameters';
import serviceFactory from '../serviceFactory';

const fields = structureGeocodeParameters();

function checkBestResult(data) {
    const result = Boolean(data.bestResult);
    delete data.bestResult;
    return result;
}

function modifyRequestParams(data) {
    const bestResult = checkBestResult(data);
    if (bestResult) {
        data.limit = 1;
        data.offset = 0;
    }
}

function handleBatchServiceCall(data) {
    data.batchItems.forEach(modifyRequestParams);
    return structuredGeocodeRest.batch(fields, data).then(results => batchModel(results));
}

function handleServiceCall(data) {
    const bestResult = Boolean(data.bestResult);
    modifyRequestParams(data);
    return structuredGeocodeRest.structuredGeocode(fields, data).then((response) => {
        if (bestResult) {
            return geocodeModel(response)[0];
        } else {
            return geocodeModel(response);
        }
    });
}

export const structuredGeocode = serviceFactory(
    fields,
    SERVICE_TYPES.SEARCH,
    'structuredGeocode',
    handleServiceCall,
    handleBatchServiceCall
);
