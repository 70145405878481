/* eslint-disable max-len */
/**
 * Provides current traffic incidents in a given region, on a given zoom level with a given style using the TomTom
 * [Traffic API - Traffic Incidents Details](TRAFFIC_INCIDENT_DETAILS_URL)
 *
 * The current road situation is updated every minute. Please use the
 * {{#crossLink "IncidentViewport"}}{{/crossLink}} service in conjunction with your request to get traffic
 * incidents aligned with other services.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * This service extends API response by providing `toGeoJson()` method, which converts incidents data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 *  We convert original properties from the API response to more readable format:
 * * `cbl` - `clusterBounds`
 * * `ic` - `incidentCategory`
 * * `ty` - `incidentSeverity`
 * * `cs` - `clusterSize`
 * * `d` - `description`
 * * `c` - `incidentCause`
 * * `f` - `from`
 * * `t` - `to`
 * * `r` - `roadNumber`
 * * `dl` - `delaySeconds`
 * * `l` - `lengthMeters`
 * * `v` - `vectorGeometry`
 *
 * Please refer directly to [Traffic API - Traffic Incidents Details](TRAFFIC_INCIDENT_DETAILS_URL) too see exactly what they mean.
 *
 * To read more about services responses take a look at {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class incidentDetails
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses LanguageMixin
 * @uses BoundingBoxMixin
 * @uses ProtocolMixin
 * @constructor
 * @param {Object} [options]
 *
 * @example
 * ```javascript
 * function callbackFn(response) {
 *   console.log(response.toGeoJson());
 * }
 * tt.services.incidentDetails({
 *   key: '<Your API key>',
 *   boundingBox: '0,0,1,1',
 *   style: 's1',
 *   zoomLevel: 12
 * }).then(callbackFn);
 * ```
 */
/* eslint-enable max-len */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import incidentDetailsModel from 'model/traffic/incidentDetails';
import validators from './validators';
import converters from './converters';
import { incidentDetailsRest } from 'rest/incidentDetails';
import parameterApplications from '../common/parameterApplications';
import serviceFactory from './serviceFactory';
import constants from '../../core/constants';

const fields = {
    key: {
        validators: [validators.key]
    },

    /**
     * The zoom level of a currently displayed map.
     * It is needed as the style (visibility, clustering, etc...) depends on the current zoom level.
     * @attribute zoomLevel
     * @param {Number} options.zoomLevel Zoom level from `0` to `22`
     */
    zoomLevel: {
        validators: [validators.zoomLevel],
        required: true,
        application: parameterApplications.PATH,
        name: 'zoom'
    },

    boundingBox: {
        converters: [converters.boundingBox],
        validators: [validators.boundingBox],
        required: true,
        application: parameterApplications.PATH,
        cast: (boundingBox, requestOptions) => {
            requestOptions.minLon = boundingBox.minLon;
            requestOptions.maxLon = boundingBox.maxLon;
            requestOptions.minLat = boundingBox.minLat;
            requestOptions.maxLat = boundingBox.maxLat;
        }
    },

    /**
     * The style that will be used to render the
     * traffic tile in the Maps API. This will have an effect on the coordinates of traffic incidents in the
     * reply.
     *
     * @attribute style
     * @param {String} options.style Style name, needs to be one of _'s1', 's2', 's3', 'night'_. This needs to match
     *     the style of your raster traffic tiles to match traffic tubes with traffic icons.
     */
    style: {
        validators: [validators.oneOfValue(['s0', 's0-dark', 's1', 's2', 's3', 'night'], 'traffic style')],
        required: true,
        application: parameterApplications.PATH
    },

    language: {
        converters: [converters.incidentDetailsLanguage],
        validators: [validators.incidentDetailsLanguage]
    },

    /**
     * Number referencing the traffic model to use.
     * This can be obtained from the {{#crossLink "IncidentViewport"}}{{/crossLink}} service.
     *
     * It is updated every minute, and is valid for two minutes before it times out.
     *
     * If a wrong Traffic Model ID is specified, the correct one will be returned by the interface.
     *
     * The default value of `-1` will always invoke the most recent traffic model. Nevertheless, it is
     * good practice to use the value obtained from the service as this will guarantee an alignment
     * with the raster traffic layer.
     *
     * @attribute trafficModelID
     * @param {Number} [options.trafficModelID] A valid, not older than two minutes, traffic model ID.
     */
    trafficModelID: {
        validators: [validators.string],
        defaultValue: constants.VIEWPORT_DEFAULT_VALUE,
        application: parameterApplications.PATH
    },

    /**
     * If passed, additional field `poi.v` is added to the response
     * and `properties.vectorGeometry` is added to the return value of _toGeoJson()_ method.
     * Two values are allowed:
     * * `original` places incidents precisely on the road.
     * * `shifted` moves the incident slightly (depending on the zoom level) to indicate specific road lanes.
     *
     * Returned vector geometry is encoded using
     * <a href="https://developers.google.com/maps/documentation/utilities/polylinealgorithm">Encoded Polyline
     * Algorithm Format</a>.
     *
     * @attribute geometries
     * @param {String} [options.geometries] The type of vector geometry added to incidents.
     */
    geometries: {
        validators: [validators.oneOfValue(['shifted', 'original'], 'traffic geometries')]
    },

    /**
     * Tells the service to separately list all traffic incidents in a cluster.
     *
     * When is enabled the clusters in the response will be represented as geometry collections,
     * and the cluster coordinates will appear as clusterCoordinates parameters.
     *
     * Enabling this feature is useful when the user wants to obtain details of the incidents
     * that are being clustered, or wants to perform a client-side clustering.
     *
     * Note that when this is set to _false_, the _toGeoJson()_ method available
     * in the resolved response will return only unclustered POIs, but **no clusters**.
     *
     * @attribute expandCluster
     * @param {Boolean} [options.expandCluster=false] Determines if the service should include details of incidents
     * that are clustered.
     */
    expandCluster: {
        validators: [validators.bool],
        defaultValue: false
    },

    /**
     * If this is set to true, the _toGeoJson()_ method available
     * in the resolved response will return features with an additional `properties.features`
     * field containing clustered features.
     *
     * Note that this option only works in conjunction with `expandCluster` set to `true`.
     *
     * @attribute preserveCluster
     * @param {Boolean} [options.preserveCluster=false] Determines if clustered incidents data
     * should be available in the cluster feature.
     */
    preserveCluster: {
        validators: [validators.bool],
        defaultValue: false
    },

    /**
     * Return the original position of the incident (`originalPosition` attribute) as well as the one
     * shifted to the beginning of the traffic tube.
     *
     * @attribute originalPosition
     * @param {Boolean} [options.originalPosition=false] Determines if the service
     * should include the original incidents' position.
     */
    originalPosition: {
        validators: [validators.bool],
        defaultValue: false
    },

    protocol: {
        validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
    },

    projection: {
        defaultValue: 'EPSG4326'
    }
};

function handleServiceCall(requestOptions) {
    const model = incidentDetailsModel(requestOptions.preserveCluster);

    return incidentDetailsRest(fields, requestOptions)
        .then(response => model(response));
}

export const incidentDetails = serviceFactory(
    fields,
    SERVICE_TYPES.TRAFFIC_INCIDENTS,
    'incidentDetails',
    handleServiceCall
);
