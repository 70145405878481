import validators from '../../services/validators';
import { nearbySearchRest } from '../../rest/nearbySearch';

/**
 * Makes a search request using the TomTom
 * [Search API - Nearby Search](NEARBY_SEARCH_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 *
 * tt.services.nearbySearch({
 *   key: <Your API key>,
 *   center: [0,0],
 *   radius: 1000
 * }).then(callbackFn);
 * ```
 *
 * @class nearbySearch
 * @namespace Services.services
 * @extends Search
 * @uses ExtendedPostalCodesForMixin
 * @uses CenterMixin
 * @uses CountrySetMixin
 * @uses OffsetMixin
 * @uses BestResultMixin
 * @uses ViewMixin
 * @uses BrandSetMixin
 * @uses OpeningHoursMixin
 * @uses CategorySetMixin
 * @uses ConnectorSetMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */

/**
 * This option specifies the search radius in meters using the coordinates given to the `center`
 * option as origin.
 *
 * @attribute radius
 * @param {Number} [options.radius=10000] A positive integer value in meters.
 */
import Search from './search';
import { NEARBY_SEARCH } from '../../common/searchTypes';

class NearbySearch extends Search {
    constructor(options) {
        super(options);

        this.fields.__all = {
            validators: []
        };

        this.fields.type = {
            defaultValue: NEARBY_SEARCH,
            visible: false
        };
        this.fields.query = {
            visible: false
        };
        this.fields.center = {
            required: true
        };
        this.fields.radius = {
            required: false,
            validators: [validators.numberInInterval(1, 50000)]
        };
        this.fields.typeahead = {
            visible: false
        };
    }

    _getRestService = () => {
        return nearbySearchRest;
    }
}

export const nearbySearch = (options) => {
    return (new NearbySearch(options)).construct('nearbySearch');
};
