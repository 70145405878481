/**
 * The copyright caption service implementation provides a caption that is supposed to be displayed on a link
 * that provides the full copyright notice. The response is not changed often.
 *
 * This service is supposed to be called once when user has the map displayed for the first time.
 *
 * ### Response
 * The response from the service is an object containing "copyrightsCaption", e.g.:
 * ```
 * {
 *   "formatVersion": "0.0.1",
 *   "copyrightsCaption": "© 1992 - 2020 TomTom."
 * }
 *```
 *
 * This text ("© 1992 - 2020 TomTom.") has to be displayed as a copyrights prompt when using TomTom services.
 * The caption should be clickable. User should be presented with the full copyrights notice when
 * the caption is clicked. Full copyright notice can be retrieved from the
 * {{#crossLink "Services.services.copyrights"}}Copyrights service{{/crossLink}}.
 *
 *
 * @example
 * ```js
 * tt.services.copyrightsCaption({
 *   key: <Your API key>
*  }).then(function(response) {
 *   console.log(response);
 * });
 * ```
 *
 * @class copyrightsCaption
 * @namespace Services.services
 * @module Services
 * @constructor
 * @uses KeyMixin
 * @uses ProtocolMixin
 *
 * @param {Object} [options]
 */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import { copyrightsRest } from 'rest/copyrights';
import validators from '../validators';
import serviceFactory from '../serviceFactory';

const fields = {
    key: {
        validators: [validators.key]
    },
    protocol: {
        validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
    },
    sessionId: {
        validators: [validators.string]
    }
};

function handleServiceCall(data) {
    return copyrightsRest.caption(fields, data);
}

export const copyrightsCaption = serviceFactory(fields, SERVICE_TYPES.MAP, 'copyrightsCaption', handleServiceCall);
