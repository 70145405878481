/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/**
 * Makes a placeById request using [Place by Id API](PLACE_BY_ID_URL).
 *
 * The Place by Id service endpoint provides detailed information about the Place found by its identifier (entityId).
 * Currently, Place by Id only supports POI (Points of Interest) ids.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: "pizza"
 * }).then(function(response) {
 *      tt.services.placeById({
 *          entityId: response.results[0].id,
 *          key: <Your API key>
 *      }).then(function(placeByIdResult) {
 *          console.log(placeByIdResult);
 *      });
 *   });
 * ```
 *
 * @class placeById
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses LanguageMixin
 * @constructor
 *
 * @param {Object} [options]
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import validators from '../validators';
import converters from '../converters';
import { placeByIdRest } from '../../rest/placeById';
import serviceFactory from '../serviceFactory';
import searchModel from '../../model/search/search';

const fields = {
    key: {
        validators: [validators.string]
    },
    /**
     * @attribute entityId
     * @param {String} [options.entityId] The identifier of a specific POI,
     * e.g. `g6Fjo05MRKJpZK81MjgwMDkwMDQyNDY3OTKhdqdVbmlmaWVk`.
     */
    entityId: {
        validators: [validators.string],
        required: true
    },
    language: {
        validators: [validators.languageCode],
        converters: [converters.language]
    }
};

function handleServiceCall(data) {
    return placeByIdRest.placeById(fields, data)
        .then((response) => searchModel(response));
}

export const placeById =
    serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'placeById',
        handleServiceCall
    );
