/* eslint-disable max-len */
/**
 * Makes a search request using the TomTom
 * [Search API - Along Route Search](ALONG_ROUTE_SEARCH_URL).
 *
 * The Search Along Route endpoint allows you to perform a fuzzy search for
 * POIs along a specified route. This search is constrained by specifying the
 * Detour Time limiting measure.
 *
 * To send the route points, this service will use a POST request with the  {{#crossLink "Services.services.alongRouteSearch/route:parameter"}}{{/crossLink}} encoded as a JSON payload.
 * The minimum number of route points is 2.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 *```js
 * tt.services.alongRouteSearch({
 *     key: <Your API key>,
 *     limit: 20,
 *     maxDetourTime: 120,
 *     query: 'gas station',
 *     route: [
 *         {
 *             'lat': 37.7524152343544,
 *             'lon':-122.43576049804686
 *         },
 *         {
 *             'lat': 37.70660472542312,
 *             'lon':-122.43301391601562
 *         },
 *         [-122.36434936523438, 37.712059855877314], // Another valid format
 *     ]
 *   }).then(function(response) {
 *     console.log('SUMMARY:');
 *     console.table(response.summary);
 *     console.log('RESULTS:');
 *     console.table(response.results);
 *   });
 * ```
 *
 * For a list of all available formats for routes, read the documentation for the
 * {{#crossLink "Services.services.alongRouteSearch/route:parameter"}}{{/crossLink}} option.
 *
 * @class alongRouteSearch
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses QueryMixin
 * @uses BatchMixin
 * @uses BrandSetMixin
 * @uses OpeningHoursMixin
 * @uses CategorySetMixin
 * @uses ConnectorSetMixin
 * @constructor
 *
 * @param {Object} [options]
 */
/* eslint-enable max-len */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import validators from '../validators';
import converters from '../converters';
import { searchRest } from '../../rest/search';
import { SEARCH_ALONG_ROUTE } from '../../common/searchTypes';
import parameterApplications from '../../common/parameterApplications';
import serviceFactory from '../serviceFactory';

const searchType = SEARCH_ALONG_ROUTE;

const fields = {
    key: {
        validators: [validators.key]
    },

    /**
     * The maximum allowed value is 3600.
     *
     * @attribute maxDetourTime
     * @param {Number} [options.maxDetourTime] New maximum detour time in seconds.
     */
    maxDetourTime: {
        validators: [validators.integerInInterval(1, 3600)],
        required: true
    },

    /**
     * @attribute spreadingMode
     * @param {String} [options.spreadingMode] Enables the spreading of returned results evenly along the route.
     */
    spreadingMode: {
        validators: [validators.oneOfValue(['auto'], 'spreading mode')]
    },

    /**
     * The following formats are supported:
     *
     * - **String[]** (longitude/latitude pairs)
     *   - `["4.8,52.3", "4.8,52.3"]`
     * - **Array[]** (longitude/latitude pairs)
     *   - `[ [4.8,52.3], [4.8,52.3] ]`
     * - **Object[]**
     *   - `{lon: 4.8, lat: 52.3}`
     *   - `{lng: 5.8, lat: 53.3}`
     *   - `{x: 53.3, y: 5.8}`
     *   - `{longitude: 5.8, latitude: 53.3}`
     *   - `{lng: Function, lat: Function}` The functions should return a numeric value.
     *
     * @example
     *```js
     * function callbackFn(response) {
     *   console.log(response);
     * }
     *
     * tomtom.alongRouteSearch({
     *   route:[
     *     {
     *         lat: 37.7524152343544,
     *         lon:-122.43576049804686
     *     },
     *     {
     *         lat: 37.70660472542312,
     *         lon:-122.43301391601562
     *     }
     *   ]
     * })
     *
     * .then(callbackFn);
     *```
     *
     * @attribute route
     * @param {Array} [options.route] Route representation.
     */
    route: {
        required: true,
        converters: [converters.route],
        validators: [validators.route],
        application: parameterApplications.POST
    },

    query: {
        required: true,
        validators: [validators.string],
        application: parameterApplications.PATH
    },

    // A custom "limit" docblock is used because the max limit value differs from the one used on the
    // other services (100).
    /**
     * @attribute limit
     * @param {Number} [options.limit=10] The maximum number of elements in the response. The maximum number is 20.
     */
    limit: {
        validators: [validators.integerInInterval(1, 20)]
    },

    type: {
        validators: [validators.string],
        defaultValue: searchType,
        application: parameterApplications.PATH
    }
};

const handleServiceCall = (data) => {
    return searchRest.search(fields, data);
};

const handleBatchServiceCall = (data) => {
    return searchRest.batch(fields, data);
};

export const alongRouteSearch =
    serviceFactory(fields, SERVICE_TYPES.SEARCH, 'alongRouteSearch', handleServiceCall, handleBatchServiceCall);
