/* eslint-disable max-len */
/**
 * Makes a search request using the TomTom
 * [Search API - Geometry Search](GEOMETRY_SEARCH_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * const geometryList = [
 *   {
 *     type: 'POLYGON',
 *     vertices: [
 *       '37.7524152343544,-122.43576049804686',
 *       '37.70660472542312,-122.43301391601562',
 *       '37.712059855877314,-122.36434936523438',
 *       '37.75350561243041,-122.37396240234374'
 *     ]
 *   },
 *   {
 *     type: 'CIRCLE',
 *     position: '37.71205,-121.36434',
 *     radius: 6000
 *   },
 *   {
 *     type: 'CIRCLE',
 *     position: '37.31205,-121.36434',
 *     radius: 1000
 *   }
 * ];
 *
 * function callbackFn(results) {
 *   console.log(results);
 * }
 *
 * tt.services.geometrySearch({
 *   key: <Your API key>,
 *   geometryList: geometryList
 * }).then(callbackFn);
 * ```
 *
 * @class geometrySearch
 * @extends Search
 * @namespace Services.services
 * @uses QueryMixin
 * @uses IdxSetMixin
 * @uses ExtendedPostalCodesForMixin
 * @uses GeometryList
 * @uses ViewMixin
 * @uses OpeningHoursMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */
/* eslint-enable */

import validators from '../validators';
import converters from '../converters';
import Search from './search';
import { boundingBox } from './searchParameters';
import parameterApplications from '../../common/parameterApplications';
import { GEOMETRY_SEARCH } from '../../common/searchTypes';

class GeometrySearch extends Search {
    constructor(options) {
        super(options);

        this.fields.type = {
            defaultValue: GEOMETRY_SEARCH,
            visible: false,
            application: parameterApplications.PATH
        };

        this.fields.key = {
            validators: [validators.key]
        };

        this.fields.limit = {
            validators: [validators.limit]
        };

        this.fields.language = {
            validators: [validators.languageCode]
        };

        this.fields.geometryList = {
            validators: [validators.geometryList],
            converters: [converters.geometryList],
            application: parameterApplications.POST
        };

        this.fields.idxSet = {
            validators: [validators.string]
        };

        this.fields.protocol = {
            validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
        };

        this.fields.extendedPostalCodesFor = {
            validators: [validators.string]
        };

        this.fields.boundingBox = boundingBox();
    }
}

export const geometrySearch = (options) => {
    return (new GeometrySearch(options)).construct('geometrySearch');
};

