import { SERVICE_TYPES } from 'Core/serviceTypes';
import validators from '../validators';
import { poiDetailsRest } from '../../rest/poiDetails';
import serviceFactory from '../serviceFactory';

/**
 * Makes a poi details request using the TomTom [POI Details API](POI_DETAILS_URL).
 *
 * This service provides additional information about POIs including:
 * * rating
 * * price range
 * * photo IDs
 * * user reviews
 *
 * This service has **specific terms and conditions of usage**. For more information about this service please
 * refer to the [POI Details API documentation](POI_DETAILS_URL).
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class poiDetails
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the call.
 */
const fields = {
    key: {
        validators: [validators.string],
        required: true
    },
    /**
     * @attribute id
     * @param {String} [options.id] POI details id which is previously retrieved from a Search request.
     */
    id: {
        validators: [validators.string],
        required: true
    }
};

function handleServiceCall(data) {
    return poiDetailsRest.poiDetails(fields, data);
}

export const poiDetails = serviceFactory(fields, SERVICE_TYPES.SEARCH, 'poiDetails', handleServiceCall);
