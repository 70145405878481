import {SERVICE_TYPES} from 'Core/serviceTypes';
import validators from '../validators';
import { poiCategoriesRest } from '../../rest/poiCategories';
import serviceFactory from '../serviceFactory';

/**
 * The POI Categories service provides a full list of POI (Points of Interest) categories and subcategories together
 * with their translations and synonyms.
 * [Search API - POI Categories](POI_CATEGORIES_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 *
 * tt.services.poiCategories({
 *   key: <Your API key>,
 *   language: 'pl-PL'
 * }).then(callbackFn);
 * ```
 *
 * @class poiCategories
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses LanguageMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the call.
 */

const fields = {
    key: {
        validators: [validators.string]
    },
    language: {
        validators: [validators.languageCode]
    }
};

function handleServiceCall(data) {
    return poiCategoriesRest.poiCategories(fields, data);
}

export const poiCategories = serviceFactory(fields, SERVICE_TYPES.SEARCH, 'poiCategories', handleServiceCall);
