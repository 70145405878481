/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/**
 * Retrieve polygons (in GeoJSON format) representing administrative borders using IDs related to
 * search results entities.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: "Amsterdam"
 * }).then(function(response) {
 *      var geometryId = response.results[0].dataSources.geometry.id;
 *      tt.services.additionalData({
 *          key: <Your API key>,
 *          geometries: [geometryId]
 *      }).then(function(adpResponse) {
 *          console.log(adpResponse);
 *      })
 *   });
 * ```
 *
 * @class additionalData
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses BatchMixin
 * @uses GeometriesMixin
 * @uses GeometriesZoomMixin
 * @constructor
 *
 * @param {Object}   [options]
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import validators from '../validators';
import { additionalDataRest } from '../../rest/additionalData';
import serviceFactory from '../serviceFactory';
import batchModel from '../../model/search/batchModel';

const fields = {
    key: {
        validators: [validators.string]
    },
    geometries: {
        validators: [validators.arrayType],
        required: true
    },
    geometriesZoom: {
        validators: [validators.geometriesZoom]
    }
};

function handleServiceCall(data) {
    return additionalDataRest.additionalData(fields, data);
}

function handleBatchServiceCall(data) {
    return additionalDataRest.batch(fields, data).then(response => batchModel(response));
}

export const additionalData =
    serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'additionalData',
        handleServiceCall,
        handleBatchServiceCall
    );
