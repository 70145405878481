import { singleRequestServiceFactory } from '../rest/request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from '../rest/request/batchRequestServiceFactory';
import {
    searchEndpoint,
    batchSearchQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

export const searchRest = {
    search: singleRequestServiceFactory(searchEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchSearchQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    })
};

