import {SERVICE_TYPES} from 'Core/serviceTypes';
import reverseGeocodeModel from '../../model/reverseGeocode/reverseGeocode';
import batchModel from '../../model/reverseGeocode/batchReverseGeocode';
import utils from '../utils';
import { reverseGeocodeRest } from '../../rest/reverseGeocode';
import serviceFactory from '../serviceFactory';

const TYPES = {
    reverseGeocode: 'reverseGeocode',
    crossStreetLookup: 'reverseGeocode/crossStreet'
};

function ReverseGeocodeBase(options) {
    this.options = options;
    this.fields = {};
}

ReverseGeocodeBase.prototype.TYPE = TYPES;
ReverseGeocodeBase.prototype.construct = function(serviceName) {
    const mergedFields = utils.addFields(this.fields, this.defaultFields);

    const service = serviceFactory(
        mergedFields,
        SERVICE_TYPES.SEARCH,
        serviceName || 'reverseGeocodeBase',
        this.handleServiceCall.bind(this),
        this.handleBatchServiceCall.bind(this)
    );

    return service(this.options);

};
ReverseGeocodeBase.prototype.handleServiceCall = function(data) {
    return reverseGeocodeRest.reverseGeocode(this.fields, data).then(response => reverseGeocodeModel(response));
};

ReverseGeocodeBase.prototype.handleBatchServiceCall = function(data) {
    return reverseGeocodeRest.batch(this.fields, data).then(response => batchModel(response));
};

function reverseGeocodeConstructor(options) {
    return (new ReverseGeocodeBase(options)).construct();
}

reverseGeocodeConstructor.prototype.constructor = ReverseGeocodeBase;

export default reverseGeocodeConstructor;
