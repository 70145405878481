/**
 * Allows requests to be made using the TomTom's
 * [Search API - Geocode](GEOCODE_SERVICE_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 * tt.services.geocode({
 *   key: <Your API Key>,
 *   query: 'Amsterdam, The Netherlands'
 * }).then(callbackFn);
 * ```
 *
 * @class geocode
 * @namespace Services.services
 * @module Services
 * @uses BatchMixin
 * @uses KeyMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses LimitMixin
 * @uses OffsetMixin
 * @uses LanguageMixin
 * @uses BoundingBoxMixin
 * @uses CenterMixin
 * @uses CountrySetMixin
 * @uses RadiusMixin
 * @uses BestResultMixin
 * @uses ProtocolMixin
 * @uses ViewMixin
 * @uses ExtendedPostalCodesForMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the geocode call,
 *     or an array of such option objects to perform a batch request.
 *
 */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import geocodeModel from 'model/geocode/geocode';
import { geocodeRest } from 'rest/geocode';
import batchModel from 'model/geocode/batchGeocode';
import geocodeParams from './geocodeParameters';
import serviceFactory from '../serviceFactory';

const fields = geocodeParams.create();

function handleServiceCall(requestOptions) {
    return geocodeRest.geocode(fields, requestOptions)
        .then(response => requestOptions.bestResult ? geocodeModel(response.results[0]) : geocodeModel(response));
}

function handleBatchServiceCall(data) {
    return geocodeRest.batch(fields, data).then(results => batchModel(results));
}

export const geocode =
    serviceFactory(fields, SERVICE_TYPES.SEARCH, 'geocode', handleServiceCall, handleBatchServiceCall);
