import { batchRequestServiceFactory } from '../rest/request/batchRequestServiceFactory';
import { singleRequestServiceFactory } from '../rest/request/singleRequestServiceFactory';

import {
    adpEndpoint,
    batchAdpQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

export const additionalDataRest = {
    additionalData: singleRequestServiceFactory(adpEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchAdpQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    })
};
