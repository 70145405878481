/* eslint-disable max-len */
/**
 * It converts geographical coordinates into a textual address representation using the TomTom
 * [Search API - CrossStreet lookup](CROSS_STREET_LOOKUP_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The final response from the service is a JSON object with the following structure:
 *
 * ```js
 * {
 *   address: {
 *     buildingNumber: 19,
 *     streetNumber: 19,
 *     street: 'N 2nd St',
 *     streetName: 'N 2nd St',
 *     crossStreet: 'E SANTA CLARA ST'
 *     streetNameAndNumber: '',
 *     speedLimit: '',
 *     countryCode: 'US",
 *     countrySubdivision: "'A',
 *     countrySecondarySubdivision: 'Santa Clara',
 *     countryTertiarySubdivision: '',
 *     municipality: 'San Jose',
 *     postalCode: '95113',
 *     municipalitySubdivision: "San Francisco Bay Area',
 *     freeformAddress: 'E SANTA CLARA ST &amp; N 2nd St, San Jose, CA, 95113'
 *   },
 *   position: {
 *     lng: -121.8898',
 *     lat: '37.337083
 *   }
 * }
 * ```
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.crossStreetLookup({
 *   key: <Your API key>,
 *   position: '19.449,51.759'
 * }).then(function(response) {
 *   console.log(response);
 * });
 * ```
 *
 * @class crossStreetLookup
 * @namespace Services.services
 * @module Services
 * @constructor
 * @uses RadiusMixin
 * @uses ProtocolMixin
 * @uses KeyMixin
 * @uses LimitMixin
 * @uses LanguageMixin
 * @uses PositionMixin
 * @uses HeadingMixin
 * @uses BatchMixin
 *
 * @param {Object} [options]
 */
/* eslint-enable max-len */
import reverseGeocodeBase from './reverseGeocodeBase';
import reverseGeocodeParams from './reverseGeocodeParameters';
import types from './types';

const ReverseGeocodeBasePrototype = reverseGeocodeBase.prototype.constructor;

function CrossStreetLookup(options) {
    ReverseGeocodeBasePrototype.call(this, options);

    this.fields = reverseGeocodeParams(types.crossStreetLookup);
}

CrossStreetLookup.prototype = new ReverseGeocodeBasePrototype();
CrossStreetLookup.prototype.constructor = CrossStreetLookup;

export const crossStreetLookup = (options) => {
    return (new CrossStreetLookup(options)).construct('crossStreetLookup');
};

