import * as Service from './service';
import { isFunction } from 'lodash';

export default (fields, serviceType, serviceName, handleServiceCall, handleBatchServiceCall) => {
    const batchFields = {
        batchItems: fields
    };

    return (options = {}) => {
        let serviceCall = handleServiceCall;
        let opt = options;
        let serviceFields = fields;

        if (options.batchItems && isFunction(handleBatchServiceCall)) {
            opt = {
                batchMode: options.batchMode,
                waitTimeSeconds: options.waitTimeSeconds,
                batchItems: options.batchItems,
                key: options.key
            };
            serviceCall = handleBatchServiceCall;
            serviceFields = batchFields;
        }

        return Service.goPrototype(
            serviceFields,
            opt,
            serviceType,
            serviceName,
            serviceCall
        );
    };
};
