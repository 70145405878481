/* eslint-disable max-len */
/**
 * Makes a search request using the TomTom
 * [Search API - Points of Interest Search](POINTS_INTEREST_SEARCH_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *    console.log(result);
 * };
 *
 * tt.services.poiSearch(({
 *   key: <Your API key>,
 *   query: 'pizza'
 * }).then(callbackFn);
 * ```
 *
 * @class poiSearch
 * @namespace Services.services
 * @extends Search
 * @uses ExtendedPostalCodesForMixin
 * @uses CenterMixin
 * @uses BoundingBoxMixin
 * @uses CountrySetMixin
 * @uses OffsetMixin
 * @uses BestResultMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses CategorySetMixin
 * @uses ViewMixin
 * @uses BrandSetMixin
 * @uses ConnectorSetMixin
 * @uses OpeningHoursMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */

/**
 * This option specifies the search radius in meters using the coordinates given to the `center`
 * option as origin.
 *
 * @attribute radius
 * @param {Number} [options.radius=0] A positive integer value in meters.
 */

/* eslint-enable */
import Search from './search';
import { boundingBox } from './searchParameters';
import { POI_SEARCH } from '../../common/searchTypes';

class PoiSearch extends Search {
    constructor(options) {
        super(options);

        this.fields.type = {
            defaultValue: POI_SEARCH,
            visible: false
        };

        this.fields.boundingBox = boundingBox();
    }
}

export const poiSearch = (options) => {
    return (new PoiSearch(options)).construct('poiSearch');
};
