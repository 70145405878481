import { matrixSyncRoutingEndpoint, matrixRoutingEndpoint } from '../../endpoints/endpoints';
import createRequestParams from '../request/requestParamsFactory';
import matrixParameters from '../../services/routing/matrixParameters';
import batchRequestStrategy from './batchRequestStrategy';

const fields = matrixParameters(), mainPostParamsName = ['origins', 'destinations'];

function getOptionalBodyParams(postParams) {
    const optionalBodyParamsNames = Object.keys(postParams)
        .filter(paramName => mainPostParamsName.indexOf(paramName) === -1);

    if (!optionalBodyParamsNames.length) {
        return null;
    }

    return optionalBodyParamsNames.reduce((accumulator, paramName) => {
        accumulator[paramName] = postParams[paramName];
        return accumulator;
    }, {});
}

function getBodyParams(postParams) {
    const bodyParams = {
            origins: postParams.origins,
            destinations: postParams.destinations
        },
        optionalBodyParams = getOptionalBodyParams(postParams);

    if (optionalBodyParams) {
        bodyParams.options = {
            post: optionalBodyParams
        };
    }

    return bodyParams;
}

export default (maxNoOfElementsInBatchRequest) => {
    return (options) => {
        const requestParams = createRequestParams(fields, options),
            queryParams = requestParams.queryParams,
            bodyParams = getBodyParams(requestParams.postParams);

        const batchMode = options.batchMode ||
            (bodyParams.origins.length * bodyParams.destinations.length <= maxNoOfElementsInBatchRequest ?
                'sync' : 'redirect');

        return batchRequestStrategy({
            batchMode,
            waitTimeSeconds: options.waitTimeSeconds,
            queryParams: queryParams,
            bodyParams: bodyParams,
            endpoints: {
                sync: matrixSyncRoutingEndpoint,
                async: matrixRoutingEndpoint
            }
        });
    };
};
