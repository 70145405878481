import {
    copyrightsWorldEndpoint,
    copyrightsBoundsEndpoint,
    copyrightsZoomEndpoint,
    captionEndpoint
} from 'endpoints';
import { singleRequestServiceFactory } from './request/singleRequestServiceFactory';
/**
 * Get copyrights information for given NDS maps areas.
 * In addition to basic copyrights for the whole map, the API is serving specific groups of copyrights for some
 * countries which are written in JSON, JSONP or XML format.
 * @example
 *  var options = {
    *      minLon: -106.8224,
    *      minLat: 34.9246,
    *      maxLon: -106.4008,
    *      maxLat: 35.3234
    * };
    *  copyrights.copyrights(options, function(response) { ... });
    *
    * @method copyrights
    * @param {Object} options Options to be passed to the copyrights call.
    * @param {Object} options.minLon Minimum longitude coordinate of bounding box.
    * @param {Object} options.minLat Minimum latitude coordinate of bounding box.
    * @param {Object} options.maxLon Maximum longitude coordinate of bounding box.
    * @param {Object} options.maxLat Maximum latitude coordinate of bounding box.
    * @param {Object} options.key API Key valid for the requested service.
    * @return {Promise} Returns a promise which will be resolved with data.
    */

/**
 * Get copyrights caption to be displayed on the map.
 * The user may request the copyrights caption. It is a short message with basic copyrights information. It
 * may be used to provide a text for a HTML link which points to more detailed information.
 * @example
 *  copyrights.caption(options, function(response) { ... });
 *
 * @method caption
 * @param {Object} options Options to be passed to the copyrights call.
 * @param {String} options.key API Key valid for the requested service.
 * @param {Function} [options.fail] The callback function to be called on failure.
 * @return {Promise} Returns a promise which will be resolved with data.
 */

export const copyrightsRest = {
    copyrightsWorld: singleRequestServiceFactory(copyrightsWorldEndpoint),
    copyrightsBounds: singleRequestServiceFactory(copyrightsBoundsEndpoint),
    copyrightsZoom: singleRequestServiceFactory(copyrightsZoomEndpoint),
    caption: singleRequestServiceFactory(captionEndpoint)
};

