import {
    reverseGeocodeEndpoint,
    batchReverseGeocodeQueryEndpoint,
    batchSearchEndpoint,
    batchSyncSearchEndpoint
} from 'endpoints';
import { singleRequestServiceFactory } from './request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from './request/batchRequestServiceFactory';

export const reverseGeocodeRest = {
    reverseGeocode: singleRequestServiceFactory(reverseGeocodeEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchReverseGeocodeQueryEndpoint,
        batch: batchSearchEndpoint,
        batchSync: batchSyncSearchEndpoint
    })
};
