import validators from 'services/validators';
import converters from 'services/converters';
import parameterApplications from 'common/parameterApplications';

export default {
    create: () => {
        return {
            extendedPostalCodesFor: {
                validators: [validators.string]
            },
            type: {
                defaultValue: 'geocode',
                application: parameterApplications.PATH
            },
            key: {
                validators: [validators.string]
            },
            query: {
                validators: [validators.string],
                required: true,
                application: parameterApplications.PATH
            },
            typeahead: {
                validators: [validators.bool]
            },
            limit: {
                validators: [validators.positiveInteger]
            },
            view: {
                validators: [validators.oneOfValue(
                    ['AR', 'IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'CN'],
                    'view')]
            },
            offset: {
                validators: [validators.naturalInteger],
                name: 'ofs'
            },
            language: {
                validators: [validators.languageCode]
            },
            boundingBox: {
                validators: [validators.boundingBox], converters: [converters.boundingBox],
                cast: (boundingBox, requestOptions) => {
                    requestOptions.topLeft = `${boundingBox.maxLat},${boundingBox.minLon}`;
                    requestOptions.btmRight = `${boundingBox.minLat},${boundingBox.maxLon}`;
                }
            },
            center: {
                converters: [converters.point],
                validators: [validators.point],
                cast: (center, requestOptions) => {
                    const coordinates = center.split(',');
                    requestOptions.lat = coordinates[0];
                    requestOptions.lon = coordinates[1];
                }
            },
            countrySet: {
                validators: [validators.countrySet]
            },
            radius: {
                validators: [validators.naturalInteger]
            },
            bestResult: {
                validators: [validators.bool],
                cast: (bestResults, requestOptions) => {
                    if (!bestResults) {
                        return;
                    }
                    requestOptions.limit = 1;
                    requestOptions.ofs = 0;
                }
            },
            protocol: {
                validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
            }
        };
    }
};
