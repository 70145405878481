import matrixFactory from '../rest/request/matrixFactory';
import { singleRequestServiceFactory } from '../rest/request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from '../rest/request/batchRequestServiceFactory';
import {
    routingEndpoint,
    calculateReachableRangeEndpoint,
    batchSyncRoutingEndpoint,
    batchRoutingEndpoint,
    batchRoutingQueryEndpoint,
    batchReachableRangeQueryEndpoint,
    longDistanceEVRoutingEndpoint
} from '../endpoints/endpoints';

const maxNoOfElementsInBatchRequest = 100;

export const routingRest = {
    calculateRoute: singleRequestServiceFactory(routingEndpoint),
    calculateReachableRange: singleRequestServiceFactory(calculateReachableRangeEndpoint),
    batch: batchRequestServiceFactory(maxNoOfElementsInBatchRequest, {
        batchSync: batchSyncRoutingEndpoint, batch: batchRoutingEndpoint,
        single: batchRoutingQueryEndpoint
    }),
    batchCalculateReachableRange: batchRequestServiceFactory(maxNoOfElementsInBatchRequest, {
        batchSync: batchSyncRoutingEndpoint, batch: batchRoutingEndpoint,
        single: batchReachableRangeQueryEndpoint
    }),
    matrix: matrixFactory(maxNoOfElementsInBatchRequest),
    longDistanceEVRouting: singleRequestServiceFactory(longDistanceEVRoutingEndpoint)
};

