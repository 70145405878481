/* eslint-disable max-len */
/* jshint ignore:start */
/**
 * Calculate a reachable range implementation using the TomTom Reachable Range API.
 *
 * Makes a route request using the TomTom [Routing API - Calculate Reachable Range](CALCULATE_REACHABLE_RANGE_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 *
 * The Calculate Reachable Range service calculates a set of locations that can
 * be reached from the origin point, subject to the available fuel or energy
 * budget that is specified in the request.
 *
 * The information returned is a polygon boundary in counterclockwise orientation
 * and the precise polygon center (the result of map-matching the origin point).
 *
 * ### Response
 * This service extends API response by providing `toGeoJson()` method, which converts range data into
 * Feature with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.6">Polygon</a> geometry.
 *
 * To read more about services responses take a look at {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 * or dive into <a target="_blank" rel=”noopener”
 * href="https://developer.tomtom.com/maps-sdk-web-js-public-preview/functional-examples#examples,code,reachable-range.html">
 * Reachable range example</a> code.
 *
 * @class calculateReachableRange
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses CommonRoutingParameters
 * @uses CalculateReachableRangeParameters
 * @uses ProtocolMixin
 * @uses BatchMixin
 * @uses ReturnsToGeoJsonMixin
 *
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 *
 * @example
 * ```javascript
 *     tt.services.calculateReachableRange({
 *         key: <Your API key>,
 *         origin: '19.454722,51.776667',
 *         fuelBudgetInLiters: 5,
 *         constantSpeedConsumptionInLitersPerHundredkm: ["10,20", "15,30"],
 *     }).then(function(rangeData) {
 *           console.log(rangeData.toGeoJson());
 *      })
 *       .catch(function(error) {
 *           console.error(error);
 *       });
 * ```
 *
 */
/* eslint-enable max-len */
/* jshint ignore:end */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import calculateReachableRangeParameters from './calculateReachableRangeParameters';
import { routingRest } from '../../rest/routing';
import reachableRangeModel from '../../model/routing/calculateReachableRange';
import batchReachableRangeModel from '../../model/routing/batchCalculateReachableRange';
import serviceFactory from '../serviceFactory';

const fields = calculateReachableRangeParameters();

const handleServiceCall = (data) => {
    return routingRest.calculateReachableRange(fields, data).then(response => reachableRangeModel(response, data));
};

const handleBatchServiceCall = (data) => {
    return routingRest.batchCalculateReachableRange(fields, data)
        .then(response => batchReachableRangeModel(response, data));
};

export const calculateReachableRange = serviceFactory(
    fields,
    SERVICE_TYPES.ROUTING,
    'calculateReachableRange',
    handleServiceCall,
    handleBatchServiceCall
);
