/**
 * Makes a search request using the TomTom
 * [Search API - Fuzzy Search](FUZZY_SEARCH_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 *
 * The list of all supported category codes are available
 * [here](SEARCH_API_SUPPORTED_CATEGORY_CODES_URL).
 *
 * ### Response
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 *
 * tt.services.fuzzySearch({
 *   key: <Your API key>,
 *   query: 'Amsterdam'
 * }).then(callbackFn);
 * ```
 *
 * @class fuzzySearch
 * @namespace Services.services
 * @extends Search
 * @uses ExtendedPostalCodesForMixin
 * @uses CenterMixin
 * @uses RadiusMixin
 * @uses BoundingBoxMixin
 * @uses CountrySetMixin
 * @uses OffsetMixin
 * @uses BestResultMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses MaxFuzzyLevel
 * @uses MinFuzzyLevel
 * @uses IdxSetMixin
 * @uses CategorySetMixin
 * @uses ViewMixin
 * @uses BrandSetMixin
 * @uses ConnectorSetMixin
 * @uses OpeningHoursMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */

import validators from '../validators';
import Search from './search';
import { boundingBox } from './searchParameters';

class FuzzySearch extends Search {
    constructor(options) {
        super(options);

        this.fields.minFuzzyLevel = {
            validators: [validators.fuzzinessLevel],
            defaultValue: 1
        };

        this.fields.maxFuzzyLevel = {
            validators: [validators.fuzzinessLevel],
            defaultValue: 2
        };

        this.fields.idxSet = {
            validators: [validators.string]
        };

        this.fields.boundingBox = boundingBox();
    }
}

export const fuzzySearch = (options) => {
    return (new FuzzySearch(options)).construct('fuzzySearch');
};
