import { singleRequestServiceFactory } from 'rest/request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from '../../services/rest/request/batchRequestServiceFactory';
import {
    structuredGeocodeEndpoint,
    batchSearchEndpoint,
    batchStructuredGeocodeQueryEndpoint,
    batchSyncSearchEndpoint
} from '../endpoints/endpoints';

export const structuredGeocodeRest = {
    structuredGeocode: singleRequestServiceFactory(structuredGeocodeEndpoint),
    batch: batchRequestServiceFactory(100, {
        single: batchStructuredGeocodeQueryEndpoint,
        batch: batchSearchEndpoint,
        batchSync: batchSyncSearchEndpoint
    })
};

